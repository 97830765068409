import { Link } from 'react-router-dom';
import './Home.css';

function Home() {
  return (
    <div className="App">
      <h1>Reden<span className="red">.</span>io</h1>
      <h2>Join the interactive podcast experience.</h2>
      <Link to="/waitlist">to the waiting list</Link>

    </div>
  );
}

export default Home;
