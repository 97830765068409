import './Waitlist.css';

function Waitlist() {
    return (
        <div className="App">
            <h2>Register with the following services:</h2>
            <ul className="ulStyleNone">
                <li>
                    <a href="#to_be_implemented">
                        github.com
                    </a>
                </li>
                <li>
                    <a href="#to_be_implemented">
                        google.com
                    </a>
                </li>
                <li>
                    <a href="#to_be_implemented">
                        apple.com
                    </a>
                </li>
                <li>
                    <a href="#to_be_implemented">
                        twitter.com
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default Waitlist;