import { useNavigate } from "react-router-dom";
import './Default.css';

function Default() {
    const navigate = useNavigate();
    return (
        <div className="App">
            <h1>Four Oh Four</h1>
            <h3>The page you are looking for does not exist</h3>
            <span>
                <a href="/">Go Home</a>
                <span className="rem2width"></span>
                <a href="#" onClick={() => navigate(-1)}>Go Back</a>
            </span>
        </div>
    );
}

export default Default;